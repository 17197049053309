export { default as NftForm } from '../../components/NftForm.vue'
export { default as Step1 } from '../../components/Step1.vue'
export { default as Step2 } from '../../components/Step2.vue'
export { default as Step3 } from '../../components/Step3.vue'
export { default as Loading } from '../../components/loading.vue'
export { default as Footer } from '../../components/footer/index.vue'
export { default as NftsChosen } from '../../components/NftsChosen/index.vue'
export { default as Header } from '../../components/header/index.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
